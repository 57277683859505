import { apiClient, IBaseResponse } from '../apiClient';

import { FILTER_URL } from '../api.constants';

export interface IRegionData {
  aRegionsByObl: IRegionItem;
}

export interface IRegionItem {
  [key: string]: {
    sOblTitle: string;
    aItems: IRegionItemArray[];
  };
}

interface IRegionItemArray {
  iIdElement: number;
  sRegionTitle: string;
}

export const regionDataRequest = async (areas: number[]): Promise<IBaseResponse<IRegionData>> =>
  await apiClient(FILTER_URL, {
    data: {
      sPresetName: 'getOblRegions',
      aOblList: areas,
      // iDebugSleepSec: 25,
    },
  });
