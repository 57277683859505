import { apiClient, IBaseResponse } from '../apiClient';
import { PAGES_URL } from '../api.constants';

export interface IPagesData {
  [key: string]: IPageConfig;
}

export type IPageConfig = {
  iId: number;
  sUid: string;
  sTitle: string;
  sPageTitle: string;
  sUri: string;
  sParentUid: string;
  iIdParent: number;
  sAccessFolder: string;
  aCustomParams: any | null;
  bCustom: boolean;
  iIdType: number;
  rFront?: {
    childRoute: string;
    component: Function;
  };
};

export const pagesRequest = async (): Promise<IBaseResponse<IPagesData>> =>
  await apiClient(PAGES_URL);
