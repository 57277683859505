import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { screenStore } from '../../../store/ScreenStore';

import MenuNav from './MenuNav';
import MenuLogo from './MenuLogo/MenuLogo';
import MenuToggleButton from '../../ui/buttons/menu/MenuToggleButton';

import styles from './Menu.module.scss';
import { MenuMobileCloseButton } from '../../ui/buttons';

const Menu = observer(function Menu() {
  const { isMiddleMenu, isMiddle, screenType, isMobileMenuOpen, isMobile } = screenStore;
  const [isHovered, setIsHovered] = useState(false);
  const menuStyle = isMiddleMenu ? styles.menu__middle : isMobile ? styles.menu__mobile : '';
  const middleStyle = isMiddleMenu && isHovered ? styles.menu__middle_open : '';
  const mobileStyle = isMobile
    ? !isMobileMenuOpen
      ? styles.menu__mobile_close
      : styles.menu__mobile_open
    : '';

  useEffect(() => {
    if (isMiddle) {
      screenStore.changeMenuType(true);
    } else {
      screenStore.changeMenuType(false);
    }
  }, [isMiddle]);

  useEffect(() => {
    screenStore.changeLogo(screenType);
  }, [screenType]);

  useEffect(() => {
    if (isMobile) {
      window.document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto';
    }
  }, [isMobile, isMobileMenuOpen]);

  const middleMenuToggleHandler = useCallback(() => {
    setIsHovered(false);
    screenStore.menuTypeToggle();
  }, []);

  const handleMouseEnter = (e: any) => {
    if (!e.target.closest('.arrow__button')) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = (e: any) => {
    if (!e.target.closest('.arrow__button')) {
      setIsHovered(false);
    }
  };

  return (
    <>
      <MenuMobileCloseButton />

      <aside
        className={`${styles.menu} ${menuStyle} ${middleStyle} ${mobileStyle}`}
        onMouseEnter={isMiddleMenu ? handleMouseEnter : undefined}
        onMouseLeave={isMiddleMenu ? handleMouseLeave : undefined}
      >
        <MenuLogo {...{ isHovered }} />

        <MenuNav {...{ isHovered, setIsHovered }} />

        <MenuToggleButton {...{ isHovered, middleMenuToggleHandler }} />
      </aside>
    </>
  );
});

export default Menu;
