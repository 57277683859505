export const LS_API_APP = 'apiApp';
export const LS_API_TOKEN = 'apiToken';

enum EErrors {
  CL_API_ERR_AUTH = 'crm_cl_api.auth_error',
  CL_API_ERR_AUTH_BLOCK_IP = 'crm_cl_api.auth_error_by_ip',
  CL_API_ERR_NONAME = 'crm_cl_api.noname_error',
  CL_API_ERR_SQL = 'crm_cl_api.sql_error',
  CL_API_ERR_NO_ACCESS = 'crm_cl_api.access_error',
  CL_API_ERR_SERVER = 'crm_cl_api.server_error',
}
export const ERROR_CODES = {
  '000001': EErrors.CL_API_ERR_AUTH,
  '000002': EErrors.CL_API_ERR_AUTH_BLOCK_IP,
  '999999': EErrors.CL_API_ERR_NONAME,
  '888888': EErrors.CL_API_ERR_SQL,
  '000003': EErrors.CL_API_ERR_NO_ACCESS,
  apiError: EErrors.CL_API_ERR_SERVER,
};

export const BASE_URL = 'https://crm2.mtkrosberg.ru/';
export const ECHO_URL = 'testing/echo/';
export const LOGO_URL = 'api/gateways/mail-ru/?';
export const WS_DEFAULT_URL = 'https://api.crm2.mtkrosberg.ru/gateway/v1/events/';
export const WS_NODE_URL = 'http://localhost:4000/gateway/v1/events/';

export const TOKEN_URL = 'api/gateways/v2/interface/getApiTokensByOneLink/';
export const USER_URL = 'api/gateways/v2/interface/getCurrentUser/';
export const PAGES_URL = 'api/gateways/v2/interface/getPages/';
export const FILTER_URL = 'api/gateways/v2/clients/getFilterData/';
export const TABS_URL = 'api/gateways/v2/clients/getTab/';
export const CLIENT_URL = 'api/gateways/v2/clients/getList/';
export const TECHNIC_URL = 'api/gateways/v2/clients/getTechnics/';
export const CHANGE_URL = 'api/gateways/v2/clients/updateClient/';
