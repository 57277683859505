class FilterService {
  getDefaultOptions(
    data: any,
    label: string = 'sTitle',
    value: string = 'iIdElement',
    isDisabled?: string,
  ) {
    return Object.values(data || {}).flatMap((el: any) => ({
      label: el[label],
      value: el[value],
      isDisabled: isDisabled ? el[isDisabled] : null,
    }));
  }
  getGroupOptions(data: any, label: string, item: string) {
    return Object.values(data || {}).flatMap((el: any) =>
      Object.values(el.aItems).flatMap((el: any) => ({
        label: el[label],
        value: el[item],
      })),
    );
  }

  getGroupOptionsWithTitle(
    data: any,
    label: string = 'sCategoryTitle',
    itemsLabel: string = 'sOblTitle',
    itemsValue: string = 'iIdElement',
    itemsCode?: string,
  ) {
    return Object.values(data ?? {}).flatMap((el: any) => ({
      label: el[label],
      items: Object.values(el.aItems).flatMap((el: any) => ({
        label: el[itemsLabel],
        value: itemsCode
          ? { areasValue: el[itemsValue], code: el[itemsCode] }
          : el[itemsValue],
      })),
    }));
  }

  getOpfOptions(data: any) {
    return Object.values(data || {}).flatMap((el: any) => ({
      label: `${el.sTitle} - ${el.sFullTitle}`,
      value: el.iIdElement,
    }));
  }
}

export const filterService = new FilterService();
