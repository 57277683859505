import { apiClient, IBaseResponse } from '../apiClient';

import {
  EFilterDataPresets,
  IFilterData,
} from '../../components/company/types/companyFilter.types';

import { FILTER_URL } from '../api.constants';

export const filterDataRequest = async (
  preset: EFilterDataPresets,
): Promise<IBaseResponse<IFilterData>> =>
  await apiClient(FILTER_URL, {
    data: { sPresetName: preset },
  });
