type StringRecord<T> = Record<string, T>;
type NumberRecord<T> = Record<number, T>;

export interface IFilterData {
  aActivitiesList: StringRecord<IActivitiesListItem>;
  aAddressTypes: StringRecord<IBaseElement>;
  aCategorySalesList: StringRecord<ICategorySalesItem>;
  aClientGroupsList: StringRecord<IBaseElement>;
  aClientManagers: StringRecord<IClientManagerItem>;
  aClientStatusList: StringRecord<IBaseElement>;
  aClientTypes: StringRecord<IBaseElement>;
  aOblList: IOblList;
  aSellersList: StringRecord<IBaseElement>;
  aGlobalLabelsList: StringRecord<GlobalMarksItems>;
  aDocYearsList: number[];
  aOrderList: NumberRecord<SortOptionsItem>;
  aOpfList: StringRecord<OpfItem>;
  aCountryCodes: { aItems: any; aRegionCodes: string[] };
}

interface IActivitiesListItem {
  aItems: StringRecord<IActivityItem>;
  sTitleCategory: string;
}

interface IActivityItem {
  iIdElement: number;
  sItemTitle: string;
}

interface ICategorySalesItem {
  iIdElement: number;
  sFullTitle: string;
  sShortTitle: string;
}

interface IClientManagerItem {
  aItems: StringRecord<IManger>;
  sDepartmentTitle: string;
}

interface IManger {
  iManagerId: number;
  sFullName: string;
  sLastName: string;
  sName: string;
}

interface IOblList {
  sCategoryTitle: string;
  aItems: StringRecord<IOblListItem>;
}

interface IOblListItem {
  iIdElement: number;
  sOblTitle: string;
  sRegionPhoneCode: string;
}

interface GlobalMarksItems {
  sGroupName: string;
  aItems: StringRecord<IBaseElement>;
}

export interface SortOptionsItem {
  title: string;
}

interface OpfItem {
  iIdElement: number;
  iSort: number;
  sFullTitle: string;
  sShowTitle: string;
  sTitle: string;
}

interface IBaseElement {
  iIdElement: number;
  sTitle: string;
  sAccessPermissions: string;
  bDisableExit?: boolean;
}

export enum EFilterDataPresets {
  FULL_FILTER = 'fullFilter',
  CHANGE_MANAGER = 'forChangeClientManager',
}

export enum EFilterTabs {
  general,
  sales,
  additional,
}

export interface IFilterFieldItem {
  sGroupName: string;
  key: string;
  items: IFilterManager[];
}
interface IFilterManager {
  title: string;
  value: number;
  searchStr: string;
  key: string;
}

export enum EGeneralFilterManagerTab {
  MAIN = 'mainManager',
  CATEGORY = 'categoryManager',
}

// Возможные поля для фильтрации
export interface IFilterRequestFields {
  importantOptions?: (string | number)[]; //'Важность клиента:'
  categoryOptions?: (string | number)[]; //'Категория ABC:'
  clientTypes?: (string | number)[]; //'Тип клиента:'
  clientStatus?: (string | number)[]; //'Статус клиента:'
  categorySalesList?: (string | number)[]; //'Продажи осуществлялись по категориям:'
  sellersList?: (string | number)[]; //'Получатель платежа:'
  connection1C?: (string | number)[]; //'Связь с 1С:'
  rawData?: (string | number)[]; //'Неразобранные данные:'
  contactInformation?: (string | number)[]; //'Наличие контактной иформации:'
  deleteMark?: (string | number)[]; //'Метка на удаление:'
  salaryScheme?: (string | number)[]; //'Схема начисления ЗП:'
  groupAssociations?: number | null; //'Принадлежность к группам:'
  groupList?: (string | number)[]; //'Список групп:'
  activityAssociations?: number | null; //'Принадлежность к видам деятельности:'
  activityTypes?: (string | number)[]; //'Виды деятельности:'
  globalMarkAssociations?: number | null; //'Принадлежность к глобальным меткам:'
  globalMarks?: (string | number)[]; //'Глобальные метки:'
  documentSearch?: (string | number)[]; //'Искать по документу:'
  landSizeFrom?: number | null; //'Размер земли от (Га):'
  landSizeTo?: number | null; //'Размер земли до (Га):'
  startSales?: Date | null; //'Дата начала продаж:'
  endSales?: Date | null; //'Дата окончания продаж (включительно):'
  startAddToBD?: Date | null; //'Дата добавления в базу с:'
  endAddToBD?: Date | null; //'Дата добавления в базу до:'
  startPayments?: Date | null;
  finishPayments?: Date | null;
  startBills?: Date | null;
  finishBills?: Date | null;
  yearSearch?: number | null; // 'Год:'
  documentNumber?: string; //'Номер:' Номер документа
  organizationTitle?: string; //'В карточке организации содержится (любое точное совпадение):'
  headerGlobalSearch?: string; // Глобальный поиск по документу в хедере приложения
  isSimpleSearch?: boolean | null; // Чекбокс глобального поиска в хедере документа
  companyIdList?: string; // Text area внизу фильтров
  areasFilter?: (string | number)[]; // Поле фильтра областей
  districtFilter?: (string | number)[]; // Поле фильтра регионов
  managersFilter?: (string | number)[]; // Поле фильтра менеджеров
  managersInSalesCategory?: (string | number)[]; // Поле фильтра менеджеров
  showAllManagerClient?: boolean | null;
  sElementUid?: string;
}
