import { makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ROUTES } from '../../routes/routes';
import { userStore } from '../userStore/UserStore';
import { tokensRequest } from '../../api/requests/tokensRequest';
import { LS_API_APP, LS_API_TOKEN } from '../../api/api.constants';

export enum TAuthState {
  NO_AUTH,
  AUTH_SUCCESS,
  BLOCK_IP,
}

class AuthStore {
  authError: string | null = null;
  authQlToken: string = '';
  authState: TAuthState = TAuthState.NO_AUTH;
  isAppLoaded = false;
  urlRegex = /^\/auth\/(?<token>\S+)$/gm;

  constructor() {
    makeAutoObservable(this);
  }
  setAuthState(value: TAuthState) {
    this.authState = value;
  }

  setAppIsLoaded() {
    this.isAppLoaded = true;
  }

  setQlToken(token: string) {
    this.authQlToken = token;
  }

  setAuthError(message: string | null) {
    this.authError = message;
  }

  getValidatedAuthUrl(url: string) {
    return this.urlRegex.exec(url)?.groups;
  }

  async fetchToken(token: string, navigate: NavigateFunction) {
    userStore.logoutUser({});
    this.setAuthError('');
    const tokensResponse = await tokensRequest(token);
    const tokensData = tokensResponse?.dataArray;

    if (!tokensResponse?.hasErrors && tokensData?.apiToken && tokensData?.apiApp) {
      localStorage.setItem(LS_API_TOKEN, tokensData.apiToken);
      localStorage.setItem(LS_API_APP, tokensData.apiApp);
    } else {
      this.setAuthError('Неверный токен или истёк срок его действия!');
      navigate(ROUTES.AUTH);
    }
  }
}

export const authStore = new AuthStore();
