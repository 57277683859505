import axios from 'axios';
import { BASE_URL, ERROR_CODES, LS_API_APP, LS_API_TOKEN } from './api.constants';

export interface IBaseResponse<T> {
  aAuthUser: {
    sClientIp: string;
    sFullName: string;
    sDepartment: string;
    sEconomyUid: string;
  };
  dataArray: T;
  sApiHandler: string;
  sApiSection: string;
  hasErrors?: boolean;
  errorCode?: string;
  errorMessage?: string;
}

export const apiClient = axios.create({
  baseURL: BASE_URL,
  method: 'POST',
});

// Перехват запроса для установки хедеров
apiClient.interceptors.request.use((config) => {
  const apiToken = localStorage.getItem(LS_API_TOKEN);
  const apiAppToken = localStorage.getItem(LS_API_APP);

  config.headers['X-Api-Token'] = apiToken;
  config.headers['X-Api-App'] = apiAppToken;

  return config;
});

// Перехват ответа для обработки ошибок
apiClient.interceptors.response.use(
  (response) => {
    const { data } = response;
    const isErrors = data.hasErrors;
    const isInterface = data.sApiSection === 'interface';
    const isTokenRequest = data.sApiHandler === 'getApiTokensByOneLink';
    const errorCode = data.errorCode as keyof typeof ERROR_CODES;

    if (isErrors && !isInterface && !isTokenRequest) {
      if (ERROR_CODES[errorCode]) {
        dispatchAppEvent(errorCode, data);
      } else {
        getApiErrorEvent(data);
      }
    }

    return data;
  },
  (error) => getApiErrorEvent(error),
);

// Генерация кастомных событий(ошибок)
function dispatchAppEvent(errorCode: string, data: any) {
  window.dispatchEvent(
    new CustomEvent(ERROR_CODES[errorCode as keyof typeof ERROR_CODES], {
      detail: { ...data },
    }),
  );
}

// Генерация ошибки сервера
function getApiErrorEvent(data: any) {
  dispatchAppEvent('apiError', {
    errorMessage: 'Ошибка сервера API',
    errorData: data,
  });
}

export const isTokensExist = () => {
  const apiToken = localStorage.getItem(LS_API_TOKEN);
  const apiAppToken = localStorage.getItem(LS_API_APP);

  return !!(apiToken && apiAppToken);
};

export const getAuthTokens = () => {
  const apiToken = localStorage.getItem(LS_API_TOKEN);
  const apiApp = localStorage.getItem(LS_API_APP);

  return { apiToken, apiApp };
};
