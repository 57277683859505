import { useEffect } from 'react';
import { SocketApi } from '../../api/socketApi/socketApi';
import { userStore } from '../../store/userStore/UserStore';

export const useSetTabActiveStatus = () => {
  const { isActiveTab } = userStore;

  useEffect(() => {
    SocketApi.getClient()?.emit('setTabActiveStatus', { data: isActiveTab });
  }, [isActiveTab]);
};
