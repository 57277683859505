import React from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from './routes';
import { isTokensExist } from '../api/apiClient';
import { IPageConfig } from '../api/requests/pagesRequest';
import { useAuth } from '../hooks/auth/useAuth';
import { userStore } from '../store/userStore/UserStore';
import { authStore, TAuthState } from '../store/authStore/AuthStore';
import { LOADER_INL_CENTER } from '../constants/loaderTemplate';

import Layout from '../components/layouts/Layout';
import CompanyInfo from '../components/company/CompanyInfo';
import BaseLoader from '../components/helpers/Loader/BaseLoader';
import { Auth, Main, NotFound, OrganizationsPage, Tasks } from '../pages';
import { CompanyCardMain } from '../components/company/companyCard';

interface IRouteComponentProps {
  config: IPageConfig;
}

interface IRoutesArray {
  [key: string]: {
    component: React.ComponentType<IRouteComponentProps>;
    childRoutes?: {
      path: string;
      childComponent?: React.ComponentType<any>;
    }[];
  };
}

const routesArray: IRoutesArray = {
  '85859a48-bed0-4618-8de8-68e78cbe6462': {
    component: Main,
  },
  '93798e82-20ba-460c-851e-ffab99cc6ebc': {
    component: OrganizationsPage,
    childRoutes: [
      { path: '', childComponent: CompanyInfo },
      { path: ':uid', childComponent: CompanyCardMain },
    ],
  },
  '6a525a66-09a1-4061-ae24-465703924e80': {
    component: Tasks,
  },
};

const AppRouter = observer(function AppRouter() {
  const { pages } = userStore;
  const { authState, isAppLoaded } = authStore;

  useAuth();

  return (
    <Routes>
      {authState === TAuthState.NO_AUTH && !isTokensExist() && (
        <>
          <Route path={ROUTES.AUTH} element={<Auth />} />
          <Route path={ROUTES.AUTH_URL} element={<BaseLoader template={LOADER_INL_CENTER} />} />
          <Route path="*" element={<Navigate to={ROUTES.AUTH} />} />
        </>
      )}

      {authState === TAuthState.NO_AUTH && isTokensExist() && (
        <Route path="*" element={<BaseLoader template={LOADER_INL_CENTER} />} />
      )}

      {isAppLoaded && authState === TAuthState.AUTH_SUCCESS && (
        <>
          <Route path={'*'} element={<NotFound />} />
          <Route path={ROUTES.AUTH} element={<Navigate to={ROUTES.ROOT} />} />
          <Route path={ROUTES.AUTH_URL} element={<Navigate to={ROUTES.ROOT} />} />
        </>
      )}

      {authState === TAuthState.AUTH_SUCCESS && (
        <Route path={ROUTES.ROOT} element={<Layout />}>
          {pages &&
            Object.values(pages).length > 0 &&
            Object.values(pages).map((config) => {
              const routesUid = config.sUid as keyof typeof routesArray;
              const appRoute = routesArray[routesUid];
              const Component = appRoute?.component;
              const childRoutes = appRoute?.childRoutes;

              return (
                Component && (
                  <Route
                    key={config.sUid}
                    path={config.sUri}
                    element={<Component config={config} />}
                  >
                    {childRoutes &&
                      childRoutes.length > 0 &&
                      childRoutes.map((route) => {
                        const ChildComponent = route.childComponent;

                        return (
                          ChildComponent && (
                            <Route
                              key={route.path}
                              path={route.path}
                              element={<ChildComponent />}
                            />
                          )
                        );
                      })}
                  </Route>
                )
              );
            })}

          {!pages && <Route path={ROUTES.ROOT} element={<Main />} />}
        </Route>
      )}
    </Routes>
  );
});

export default AppRouter;
