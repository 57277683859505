import './menu_link-item.scss';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';

import { menuConfigs } from '../../../../constants/menu/menuConfigs';
import { userStore } from '../../../../store/userStore/UserStore';
import { screenStore } from '../../../../store/ScreenStore';
import { filterStore } from '../../../../store/filter/FIlterStore';

import styles from './MenuLink.module.scss';
import { IPageConfig } from '../../../../api/requests/pagesRequest';

type MenuLinkProps = {
  config: IPageConfig;
  isHovered?: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuLink = observer(function MenuLink({ config, isHovered, setIsHovered }: MenuLinkProps) {
  const navigate = useNavigate();
  const { currentPage } = userStore;
  const { isMiddleMenu } = screenStore;
  const [activeItem, setActiveItem] = useState<null | number>(null);
  const isCurrentPage = currentPage?.sUid === config.sUid;
  const isShow = isMiddleMenu && !isHovered;
  const { badgeTemplate, icon, hasBadge } = menuConfigs[config.sUid as keyof typeof menuConfigs];

  const closeMenuHandler = () => {
    screenStore.mobileMenuToggle();
    setIsHovered(false);
    navigate(config.sUri);
  };

  const paramClickHandler = (request: any, index: number) => {
    filterStore.setFilterRequest(request.rFilterData);
    filterStore.updateFilterInputs(request.rFilterData);
    filterStore.setSortValue(request.iSortPreset);
    setActiveItem(index);
    screenStore.mobileMenuToggle();
    setIsHovered(false);
    navigate(config.sUri);
  };

  const renderLink = () => (
    <div className={`${styles.menu__link} ${isCurrentPage ? styles.active : ''}`}>
      {icon}
      <div className={`d-flex flex-fill ${isShow ? 'd-none' : styles.menu__animation}`}>
        <div className="ps-3 flex-grow-1">{config.sTitle}</div>

        {hasBadge && (
          <Badge
            bg={badgeTemplate === 'ПУ' ? 'warning' : 'success'}
            className="text-uppercase rounded-1 lh-sm"
          >
            {badgeTemplate}
          </Badge>
        )}
      </div>
    </div>
  );

  return (
    <>
      {config.aCustomParams && Object.values(config.aCustomParams).length > 0 ? (
        <Dropdown>
          {renderLink()}
          <Dropdown.Toggle className="position-absolute bg-transparent w-100 h-100 rounded-0 border-0 top-0 start-0" />
          <Dropdown.Menu className={isShow ? 'd-none' : ''}>
            {Object.values(config.aCustomParams.menuPresets).map((el: any, index) => (
              <div key={index}>
                <Dropdown.Item
                  eventKey={index}
                  onClick={() => paramClickHandler(el.request, index)}
                  active={isCurrentPage && activeItem === index}
                  className="menu__link-item"
                >
                  <div className="text-decoration-none" style={{ color: 'inherit' }} role="button">
                    {el.title}
                  </div>
                </Dropdown.Item>
                {index === 0 && <Dropdown.Divider />}
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div onClick={closeMenuHandler} role="button">
          {renderLink()}
        </div>
      )}
    </>
  );
});
export default MenuLink;
