import { apiClient, IBaseResponse } from '../apiClient';
import { TOKEN_URL } from '../api.constants';

export interface ITokenData {
  apiApp: string;
  apiToken: string;
}

export const tokensRequest = async (url: string): Promise<IBaseResponse<ITokenData>> =>
  await apiClient(TOKEN_URL, { data: { token: url } });
