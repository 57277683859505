import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

export const createLazyComponent = <T extends React.ComponentType<any>>(
  LazyComponent: React.LazyExoticComponent<T>,
  fallback?: boolean,
) => {
  return (props: React.ComponentProps<T>, componentProps?: any) => {
    return (
      <Suspense
        fallback={
          fallback ? (
            <div className="w-100 vh-100 d-flex align-items-center justify-content-center z-2">
              <Spinner variant="light" animation="border" />
            </div>
          ) : null
        }
      >
        <LazyComponent {...props} {...componentProps} />
      </Suspense>
    );
  };
};

export const delayImport = (promise: any) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 5000);
  }).then(() => promise);
};
