import { apiClient, IBaseResponse } from '../apiClient';
import { USER_URL } from '../api.constants';

export interface IUserData {
  active?: number;
  department_name?: string;
  department_role_name?: string;
  economy_uid?: string;
  email?: string;
  full_name?: string;
  id?: number;
  id_department?: number;
  id_department_role?: number;
  id_parent_department?: null;
  id_permissions?: number;
  is_fake?: number;
  last_name?: string;
  linked_accounts_ids?: [];
  logo_local?: string;
  make_calls_gateways?: [];
  name?: string;
  other_department_ids?: [];
  staff_photos?: {
    '34x34'?: {
      uri: string;
      uid: string;
    };
  };
  permissions?: Record<string, string>;
  hasErrors?: boolean;
}

export const userRequest = async (): Promise<IBaseResponse<IUserData>> => await apiClient(USER_URL);
