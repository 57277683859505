import { lazy } from 'react';
import { createLazyComponent } from '../../utils/CreateLazyComponent';

const ModalErrorLazy = lazy(() => import('./Modal/ModalError/ModalError'));
const ModalQrCodeLazy = lazy(() => import('./Modal/ModalQrCode/ModalQrCode'));
const AppBreadCrumbsLazy = lazy(() => import('./Breadcrumbs/AppBreadCrumbs'));
const AppPlayerLazy = lazy(() => import('./AppPlayer/AppPlayer'));
const ToastIncomingCallLazy = lazy(() => import('./Toasts/ToastIncomingCall/ToastIncomingCall'));

export const ModalError = createLazyComponent(ModalErrorLazy, true);
export const ModalQrCode = createLazyComponent(ModalQrCodeLazy, true);
export const AppBreadCrumbs = createLazyComponent(AppBreadCrumbsLazy);
export const AppPlayer = createLazyComponent(AppPlayerLazy);
export const ToastIncomingCall = createLazyComponent(ToastIncomingCallLazy);
