import React from 'react';
import { observer } from 'mobx-react-lite';

import { screenStore } from '../../store/ScreenStore';
import { playerStore } from '../../store/PlayerStore/PlayerStore';

import styles from './Layout.module.scss';

type LayoutWrapperProps = { children: React.ReactNode };

const LayoutWrapper = observer(function LayoutWrapper({ children }: LayoutWrapperProps) {
  const { isMiddleMenu, isDesktop, isMobile } = screenStore;
  const { sourceTracks, isSmallPlayerSize } = playerStore;

  return (
    <div
      className={`${styles.app} ${isDesktop ? (isMiddleMenu ? styles.closed : styles.opened) : ''}`}
      style={{
        display: isMobile ? 'block' : 'flex',
        paddingBottom:
          Object.values(sourceTracks).length > 0 ? (isSmallPlayerSize ? 'unset' : '75px') : 'unset',
      }}
    >
      {children}
    </div>
  );
});

export default LayoutWrapper;
