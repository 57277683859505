import { makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ROUTES } from '../../routes/routes';
import { isTokensExist } from '../../api/apiClient';
import { authStore, TAuthState } from '../authStore/AuthStore';
import { LS_API_APP, LS_API_TOKEN } from '../../api/api.constants';
import { SocketApi } from '../../api/socketApi/socketApi';
import { IPageConfig, IPagesData, pagesRequest } from '../../api/requests/pagesRequest';
import { IUserData, userRequest } from '../../api/requests/userRequest';

interface ILogoutUserProps {
  totalLogout?: boolean;
}

class UserStore {
  userInfo: IUserData | null = null;
  pages: IPagesData | null = null;
  currentPage: IPageConfig | null = null;
  isActiveTab: boolean = false;
  isSocketLoaded: boolean = false;

  socketApiService: typeof SocketApi;
  authStoreService: typeof authStore;

  constructor(socketApiService: typeof SocketApi, authStoreService: typeof authStore) {
    makeAutoObservable(this);
    this.socketApiService = socketApiService;
    this.authStoreService = authStoreService;
  }

  public setUserInfo(data: IUserData | null) {
    this.userInfo = data;
  }

  public setPages(data: IPagesData | null) {
    this.pages = data;
  }

  public setSocketStatus(value: boolean) {
    this.isSocketLoaded = value;
  }

  setIsActiveTab(value: boolean) {
    this.isActiveTab = value;
  }

  setCurrentPage(currentPageData: IPageConfig) {
    this.currentPage = currentPageData;
  }

  logoutUser({ totalLogout = true }: ILogoutUserProps) {
    if (totalLogout) {
      localStorage.removeItem(LS_API_APP);
      localStorage.removeItem(LS_API_TOKEN);
    }
    this.setUserInfo(null);
    this.setPages(null);
    this.authStoreService.setAuthState(TAuthState.NO_AUTH);
    this.socketApiService.disconnectClient();
  }

  async fetchUserData(currentUrl: string, navigate: NavigateFunction) {
    if (isTokensExist() && this.userInfo === null) {
      const userResponse = await userRequest();
      const userData = userResponse?.dataArray;

      if (userData && !userResponse.hasErrors) {
        const pagesResponse = await pagesRequest();
        const pagesData = pagesResponse?.dataArray;

        if (!pagesResponse?.hasErrors) {
          this.setUserInfo(userData);
          this.setPages(pagesData);
          this.authStoreService.setAuthState(TAuthState.AUTH_SUCCESS);
          this.authStoreService.setAppIsLoaded();
          this.socketApiService.reconnectToServer();
          if (currentUrl.startsWith('/auth')) {
            navigate(ROUTES.ROOT);
          }
        } else {
          // Удаляем токены потому что они недействительны и переходим на форму авторизации
          this.logoutUser({});
          this.authStoreService.setAuthError('Страницы приложения не найдены!');
          navigate(ROUTES.AUTH);
        }
      } else {
        // Удаляем токены потому что они недействительны и переходим на форму авторизации
        this.logoutUser({});
        this.authStoreService.setAuthError('Пользователь не найден!');
        navigate(ROUTES.AUTH);
      }
    }
  }
}

export const userStore = new UserStore(SocketApi, authStore);
