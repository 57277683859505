import { lazy } from 'react';
import { createLazyComponent } from '../utils/CreateLazyComponent';

const AuthLazy = lazy(() => import('./System/Auth/Auth'));
const NotFoundLazy = lazy(() => import('./System/NotFound/NotFound'));
const TasksLazy = lazy(() => import('./Tasks/Tasks'));
const OrganizationsPageLazy = lazy(() => import('./OrganizationsPage/OrganizationsPage'));
const MainLazy = lazy(() => import('./Main/Main'));

export const Tasks = createLazyComponent(TasksLazy);
export const Auth = createLazyComponent(AuthLazy);
export const NotFound = createLazyComponent(NotFoundLazy);
export const Main = createLazyComponent(MainLazy);
export const OrganizationsPage = createLazyComponent(OrganizationsPageLazy);
