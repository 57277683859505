import { makeAutoObservable } from 'mobx';

import {
  EFilterDataPresets,
  EGeneralFilterManagerTab,
  IFilterData,
  IFilterRequestFields,
} from '../../components/company/types/companyFilter.types';

import { filterOptionsStore } from './FilterOptionsStore';
import { filterDataRequest } from '../../api/requests/filterDataRequest';
import { IRegionItem, regionDataRequest } from '../../api/requests/regionDataRequest';
import { MultiselectClass } from '../../utils/filter/multiselect-class.js';
import { rStructureAreas, rStructureMap } from '../../utils/filter/filterFieldStructure';

class FilterStore {
  filterData: IFilterData | null = null;
  isFilterDataLoading: boolean = true;
  filterRegions: IRegionItem | null = null;
  isFilterRegionsLoading: boolean = false;
  filterInputs: IFilterRequestFields = {};
  sortOrganizationsBy: number = 0;
  filterRequest: IFilterRequestFields | null = null;
  filterOptionsService: typeof filterOptionsStore;
  generalActiveTab: EGeneralFilterManagerTab = EGeneralFilterManagerTab.MAIN;
  generalTabCheckbox: boolean = true;
  managersForm: MultiselectClass | null = null;
  areasForm: MultiselectClass | null = null;

  constructor(filterOptionsService: typeof filterOptionsStore) {
    makeAutoObservable(this);
    this.filterOptionsService = filterOptionsService;
  }

  private setFilterData(data: IFilterData) {
    this.filterData = data;
    this.setManagersForm(data);
    this.setAreasForm(data);
  }

  private setFilterDataLoading(value: boolean) {
    this.isFilterDataLoading = value;
  }

  private setRegionsData(data: any) {
    this.filterRegions = data;
  }

  private setRegionsDataLoading(value: boolean) {
    this.isFilterRegionsLoading = value;
  }

  setFilterRequest(data: IFilterRequestFields | null) {
    this.filterRequest = data;
  }

  setSortValue(value: number) {
    this.sortOrganizationsBy = value;
  }

  updateFilterInputs(data: IFilterRequestFields) {
    this.filterInputs = data;
  }

  setManagersForm(data: IFilterData) {
    this.managersForm = new MultiselectClass(data?.aClientManagers || [], rStructureMap);
  }

  setAreasForm(data: IFilterData) {
    this.areasForm = new MultiselectClass(data?.aOblList || [], rStructureAreas);
  }

  setGeneralActiveTab(value: EGeneralFilterManagerTab) {
    this.generalActiveTab = value;
  }

  setGeneralTabCheckbox() {
    this.generalTabCheckbox = !this.generalTabCheckbox;
  }

  async fetchData() {
    const filterResponse = await filterDataRequest(EFilterDataPresets.FULL_FILTER);
    const filterData = filterResponse.dataArray;

    this.setFilterData(filterData);
    this.setManagersForm(filterData);
    this.filterOptionsService.setFilterOptions(filterData);
    this.filterOptionsService.setGroupOptions(filterData);
    this.filterOptionsService.setGroupOptionsWithTitle(filterData);
    this.filterOptionsService.setOpfOptions(filterData);
    this.filterOptionsService.setAddTypesOptions(filterData);
    this.setFilterDataLoading(false);
  }

  async fetchRegions(areas: number[]) {
    this.setRegionsDataLoading(true);
    const regionResponse = await regionDataRequest(areas);
    const regionData = regionResponse.dataArray;
    this.setRegionsData(regionData?.aRegionsByObl || []);
    this.filterOptionsService.setDistrictOptions(regionData);
    this.setRegionsDataLoading(false);
  }
}

export const filterStore = new FilterStore(filterOptionsStore);
